$social-media-button-bg-color:#E7AA4D;
$social-media-button-hover-border-color:#E7AA4D;

.social-media-icon{
  width:50px;
  height:50px;
}
.social-media-button{
  background-color: $social-media-button-bg-color;
  border-radius: 50%;
  margin:0 5px;
  padding:5px;
  border: 2px solid white;
  &:hover{
    border:2px solid $social-media-button-hover-border-color;
  }
}

$home-text-color:white;

* {
  //Add fonts
  font-family: 'Maven Pro', sans-serif;
}
.page-header {
  height: 100vh;
  max-height: 1050px;
  padding: 0;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-color: rgba(44, 44, 44, 0.6);
  .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .container {
    height: 100%;
    z-index: 1;
    text-align: center;
    position: relative;
    > .content-center {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 0 15px;
      color: #FFFFFF;
      width: 100%;
      max-width: 880px;
    }
  }
}



/* Profile Image Style */
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.6, 0.6);
            transform: scale(0.6, 0.6);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 0.0;
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.6, 0.6);
            transform: scale(0.6, 0.6);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 0.0;
  }
}

.cc-profile-image {
  img {
    position: relative;
    border-radius: 50%;
    height: 180px;
    width: 180px;
    padding: 0;
    margin: 0;
    border: 15px solid transparent;
    z-index: 9999;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
  a {
    position: relative;
    &:before {
      content: "";
      border: 15px solid rgba(55, 140, 63, 0.6);
      border-radius: 50%;
      height: 180px;
      width: 180px;
      position: absolute;
      left: 0;
      -webkit-animation: pulsate 1.6s ease-out;
      animation: pulsate 1.6s ease-out;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      opacity: 0.0;
      z-index: 99;
    }
  }
}
.logo {
  height: 30px;
  width: 32px;
}


.image-path-home {
  // background-color:red;
  background-image: url('../data/images/cc-bg-1.jpg');
  // height: 100vh;
  // width: 100vw;
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-description,
.home-title{
  color:$home-text-color;
}
.intro-job-title{
  color:$home-text-color;
  font-size:20px;
  width:100%;
  div{
    div{
      text-align: center;
      width:100%;
    }
  }
}

///Style for certificate on aboutme page
.badge{
  background-color: $navbar-bg-color;
  &.badge-light{
    background-color: $navbar-text-color;
  }
}
.badge-image{
  height: 100px;
  width: 100px;
}

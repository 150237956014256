.certifications {
  .card{
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: 150px;
  }
  .card-title {
    font-size: 18px;
  }
  .card-subtitle {
    font-size: 15px;
  }
  .card-body{
    padding:30px 0px 30px 0px;

  }
  .inner-body{
    border-left:10px solid $content-header-bg-color;

  }
}